import { useEffect, useState } from 'react'
import Vector from 'utils/vector'

const useMotion = () => {
	const [acceleration, setAcceleration] = useState<Vector>()

	useEffect(() => {
		if (window.DeviceMotionEvent) {
			window.addEventListener(
				'devicemotion',
				function (event: any) {
					const vector = new Vector(
						event.accelerationIncludingGravity.x,
						event.accelerationIncludingGravity.y,
						event.accelerationIncludingGravity.z
					)
					setAcceleration(vector)
				},
				true
			)
		} else {
			window.addEventListener(
				'MozOrientation',
				function (orientation: any) {
					const vector = new Vector(
						orientation.x * 50,
						orientation.y * 50,
						orientation.z * 50
					)
					setAcceleration(vector)
				},
				true
			)
		}
		return () => {
			window.removeEventListener('devicemotion', () => {})
			window.removeEventListener('MozOrientation', () => {})
		}
	}, [])

	return acceleration
}

export default useMotion
