import { getConfiguration } from 'actions/initialize'
import { getIp } from 'actions/utility'
import { Fragment, h } from 'preact'
import { useEffect } from 'preact/hooks'
import configStore from 'store/configStore'
import { ConfigurationResponseValue } from 'types/configuration'

interface Props {
	children: any
}

const InitializeProvider = ({ children }: Props) => {
	useEffect(() => {
		getConfiguration().then((res: ConfigurationResponseValue) => {
			configStore.setData(res)
			configStore.setLoading(false)
		})

		getIp().then((ip) => {
			configStore.setIp(ip)
		})

		setDefaultColors()
	}, [])

	const setDefaultColors: any = () => {
		document.documentElement.style.setProperty('--ai-bg_default', '#000000')
	}

	return <Fragment>{children}</Fragment>
}

export { InitializeProvider }
