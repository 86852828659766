import { FunctionalComponent, h } from 'preact'
import Layout from 'components/layout'
import { useEffect } from 'preact/hooks'
import { track } from 'utils/tracker'
import { ASSET_URL } from 'utils/config'

type VideoUploadedProps = NavigationProps & {}

const VideoUploaded: FunctionalComponent<VideoUploadedProps> = ({
	onMount,
	onUnMount,
	onBack,
	onNext,
}: VideoUploadedProps) => {
	useEffect(() => {
		onMount!()
		track('scan_3_uploaded')
		return () => {
			onUnMount!()
		}
	}, [])

	return (
		<Layout nextTitle="Ok" onBack={onBack} onNext={onNext}>
			<div className="text-center px-12 pt-14">
				<h3 className="text-2xl font-semibold">
					Return to the website to receive your Size Profile
				</h3>
			</div>
			<div>
				<img
					className="mx-auto mt-16"
					src={`${ASSET_URL}/video-uploaded.svg`}
				/>
			</div>
		</Layout>
	)
}

export default VideoUploaded
