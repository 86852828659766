import { Fragment, FunctionalComponent, h } from 'preact'
import { useCallback, useEffect, useRef, useState } from 'preact/hooks'
import Layout from 'components/layout'
import Modal from 'components/modal'
import useMotion from 'hooks/useMotion'
import {
	BookOpenIcon,
	CheckIcon,
	ChevronLeftIcon,
	QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline'
import { captureUserMedia, getMimeType, isVideoEnabled } from 'utils/video'
import { clamp, getUrlParam, limitDecimalPlaces } from 'utils'
import Error from 'components/error'
import { track } from 'utils/tracker'
import Instruction from 'components/instruction'
import { v4 as uuidv4 } from 'uuid'
import { uploadCameraInfo, uploadUserInfo } from 'actions/scan'
import PageLoader from 'components/pageLoader'
import infoStore from 'store/infoStore'
import fitPreferenceStore from 'store/fitPreferenceStore'
import Button from 'components/button'
import { useAnimatedText } from 'hooks/useAnimatedText'
import { MALE_GENDER } from 'utils/config'

type RecordVideoProps = NavigationProps & {
	playSkinTightLongHairAudio: () => void
	stopSkinTightLongHairAudio: () => void
	playSimpleBackgroundAudio: () => void
	stopSimpleBackgroundAudio: () => void
	playTiltPhoneAudio: () => void
	stopTiltPhoneAudio: () => void
	playStepBackAudio: () => void
	stopStepBackAudio: () => void
	playBeepAudio: () => void
	stopBeepAudio: () => void
	playRecordingAudio: () => void
	stopRecordingAudio: () => void
	playFinishedAudio: () => void
}

const RecordVideo: FunctionalComponent<RecordVideoProps> = ({
	onMount,
	onUnMount,
	onBack,
	onNext,
	playSkinTightLongHairAudio,
	stopSkinTightLongHairAudio,
	playSimpleBackgroundAudio,
	stopSimpleBackgroundAudio,
	playTiltPhoneAudio,
	stopTiltPhoneAudio,
	playStepBackAudio,
	stopStepBackAudio,
	playBeepAudio,
	stopBeepAudio,
	playRecordingAudio,
	stopRecordingAudio,
	playFinishedAudio,
}) => {
	const motion = useMotion()

	const videoRef = useRef<HTMLVideoElement | null>(null)

	const videoRecorderRef = useRef<MediaRecorder | null>(null)

	const [acceleration, setAcceleration] = useState<number[]>([])

	const captureTimeoutRef = useRef<NodeJS.Timeout | null>(null)
	const stepBackTimeoutRef = useRef<NodeJS.Timeout | null>(null)
	const beepTimeoutRef = useRef<NodeJS.Timeout | null>(null)
	const startRecordTimeoutRef = useRef<NodeJS.Timeout | null>(null)
	const simpleBackgroundTimeoutRef = useRef<NodeJS.Timeout | null>(null)

	const beepIntervalRef = useRef<NodeJS.Timeout | null>(null)

	const [requestDeviceMotion, setRequestDeviceMotion] = useState<boolean>(false)

	const [isAccuratePosition, setIsAccuratePosition] = useState<boolean>(false)
	const isAccuratePositionRef = useRef<boolean>(isAccuratePosition)
	isAccuratePositionRef.current = isAccuratePosition

	const [isVideoActive, setIsVideoActive] = useState<boolean>(true)
	const [startRecording, setStartRecording] = useState<boolean>(false)

	const [hasSetAccurate, setHasSetAccurate] = useState<boolean>(false)
	const hasSetAccurateRef = useRef<boolean>(hasSetAccurate)
	hasSetAccurateRef.current = hasSetAccurate

	const [videoRecordingComplete, setVideoRecordingComplete] =
		useState<boolean>(false)
	const [showInstruction, setShowInstruction] = useState<boolean>(false)
	const [showGuide, setShowGuide] = useState<boolean>(false)

	const [tiltPosition, setTiltPosition] = useState<number>(195)

	const [activeInstruction, setActiveInstruction] = useState<number>(0)

	const [uploading, setUploading] = useState(false)
	const [recordedChunks, setRecordedChunks] = useState([])

	const [animatedText, animatedSubText] = useAnimatedText([
		{
			text: 'Wear skin tight clothing for accurate measurements',
			subText:
				'Loose or baggy attire can introduce errors and lead to inaccurate results.',
			duration: 4000,
		},
		{
			text: 'Find room with 2m distance',
			duration: 3000,
		},
		{
			text: 'Simple Background',
			duration: 4000,
		},
		{
			text: 'If you have a long hair, tie it up to make your neck visible',
			duration: 3000,
		},
	])

	useEffect(() => {
		onMount!()
		track('scan_2_recording')
		requestUserMedia()
		return () => {
			onUnMount!()
		}
	}, [])

	const webCamProps = {
		id: 'video',
		ref: videoRef,
		style: {
			WebkitTransform: 'scaleX(-1)',
			transform: 'scaleX(-1)',
			objectFit: 'cover',
		},
	}
	useEffect(() => {
		if (motion && activeInstruction > 1 && !videoRecordingComplete) {
			let rotate = Math.acos(motion.normalise().y)
			rotate = (rotate * 180) / Math.PI
			let tilt = Math.abs(rotate - 90) / 90
			tilt = 1 - clamp(tilt, 0, 1)
			tilt = Math.round((tilt * 195) / 5) * 5

			setTiltPosition(tilt)

			if (tilt >= 30 && tilt <= 60) {
				if (!isAccuratePositionRef.current) {
					setIsAccuratePosition(true)
					setHasSetAccurate(true)

					stepBackTimeoutRef.current = setTimeout(() => {
						stopAllAudio()
						playStepBackAudio()
						setActiveInstruction(3)
						setShowInstruction(false)
					}, 4000)

					beepTimeoutRef.current = setTimeout(() => {
						stopAllAudio()
						playBeepAudio()
					}, 20500)

					startRecordTimeoutRef.current = setTimeout(() => {
						setStartRecording(true)

						setRecordedChunks([])
						setAcceleration(motion.flatten())
						startRecord()
					}, 21000)
				}
			} else {
				setIsAccuratePosition(false)

				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				clearTimeout(stepBackTimeoutRef.current!)
				stepBackTimeoutRef.current = null

				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				clearTimeout(beepTimeoutRef.current!)
				beepTimeoutRef.current = null

				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				clearTimeout(startRecordTimeoutRef.current!)
				startRecordTimeoutRef.current = null

				if (hasSetAccurateRef.current) {
					stopRecord()
					setHasSetAccurate(false)
					setShowInstruction(true)
					setActiveInstruction(2)
					stopAllAudio()
					playTiltPhoneAudio()
				}
			}
		}
	}, [motion])

	const stopAllAudio = useCallback(() => {
		stopSkinTightLongHairAudio()
		stopSimpleBackgroundAudio()
		stopTiltPhoneAudio()
		stopStepBackAudio()
		stopBeepAudio()
		stopRecordingAudio()
	}, [])

	const requestUserMedia = () => {
		const isVideo = isVideoEnabled()

		setIsVideoActive(isVideo)

		captureUserMedia(
			(stream: MediaStream) => {
				showDeviceMotionModal()

				videoRecorderRef.current = new MediaRecorder(stream, {
					mimeType: getMimeType(),
					videoBitsPerSecond: 500000,
					bitsPerSecond: 800000,
				})
				videoRef.current!.srcObject = stream
			},
			(err) => {
				if (err.message === 'Permission denied') {
					setIsVideoActive(false)
				}
			}
		)
	}

	const showDeviceMotionModal = () => {
		const motionEvent = window.DeviceMotionEvent as any
		if (
			window.DeviceMotionEvent &&
			typeof motionEvent.requestPermission === 'function'
		) {
			setRequestDeviceMotion(true)
		}
	}

	const requestDeviceMotionPermission = async () => {
		const motionEvent = window.DeviceMotionEvent as any
		return motionEvent.requestPermission()
	}

	const startRecord = useCallback(() => {
		stopAllAudio()
		playRecordingAudio()

		videoRecorderRef.current!.addEventListener(
			'dataavailable',
			handleDataAvailable
		)
		videoRecorderRef.current!.start()

		captureTimeoutRef.current = setTimeout(() => {
			clearInterval(beepIntervalRef.current!)
			stopBeepAudio()

			stopRecord()

			stopAllAudio()
			playFinishedAudio()

			setVideoRecordingComplete(true)
		}, 14000)
	}, [videoRef, videoRecorderRef])

	const handleDataAvailable = useCallback(
		({ data }: any) => {
			if (data.size > 0) {
				setRecordedChunks((prev) => prev.concat(data))
			}
		},
		[setRecordedChunks]
	)

	const stopRecord = useCallback(() => {
		if (videoRecorderRef.current?.state !== 'inactive') {
			videoRecorderRef.current?.stop()
		}

		stopBeepAudio()
		setStartRecording(false)

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		clearTimeout(captureTimeoutRef.current!)
		captureTimeoutRef.current = null
	}, [videoRecorderRef])

	const uploadVideo = async () => {
		setUploading(true)

		const uuid = getUrlParam('token') || uuidv4()

		const blob = new Blob(recordedChunks, {
			type: 'video/webm',
		})

		Promise.all([
			uploadUserInfo({
				uuid,
				userInfo: {
					first_name: infoStore.firstName.value,
					last_name: infoStore.lastName.value,
					name: infoStore.name.value,
					height: parseFloat(limitDecimalPlaces(infoStore.height.value)),
					weight: 0.0,
					gender: infoStore.gender.value,
					top_style: fitPreferenceStore.topFitting.value,
					bottom_style: fitPreferenceStore.bottomFitting.value,
					feedbacks: {},
				},
			}),
			uploadCameraInfo({
				uuid,
				camera: JSON.stringify({
					lens_model: '',
					acceleration_vector: [acceleration],
					focal_length: 0,
					model_name: '',
					camera_position: 'front',
				} as UploadCameraInfoInputCamera),
				file: new File([blob], 'video.webm'),
			}),
		])
			.then(() => {
				onNext!()
			})
			.finally(() => setUploading(false))
	}

	return (
		<Fragment>
			{!isVideoActive ? (
				<Error title="Camera not available" />
			) : (
				<Layout fullLayout hideHeader noButton>
					<div className="relative">
						<div
							className={`grid relative justify-center align-middle z-0 overflow-y-hidden ${
								startRecording
									? 'bg-green-400 px-3 py-6'
									: 'bg-[#686d76] px-0 py-0'
							} h-full`}
						>
							<Modal
								title="You are done"
								open={videoRecordingComplete}
								content="Click ok to upload video"
								onConfirm={() => {
									uploadVideo()
								}}
								onClose={() => setVideoRecordingComplete(false)}
								icon={
									<CheckIcon
										className="h-6 w-6 text-green-600"
										aria-hidden="true"
									/>
								}
								iconBg="bg-green-50"
							/>

							<video
								className={`${
									startRecording ? 'h-[85vh]' : 'dynamic-h'
								} w-screen touch-none`}
								autoPlay
								muted
								playsInline
								{...webCamProps}
							/>
						</div>

						<PageLoader open={uploading} />

						<Modal
							title="Device Motion and Orientation Access"
							open={requestDeviceMotion}
							content="We need access to the device motion and orientation to provide you accurate results."
							onConfirm={() => {
								setRequestDeviceMotion(false)
								requestDeviceMotionPermission().then(() => {
									setShowInstruction(true)
								})
							}}
							onClose={() => {}}
						/>

						<Modal
							showClose={activeInstruction === 3}
							children={
								<div>
									<div className="basis-10/12 text-center mb-2 border-b-2 pb-2">
										{Instruction(activeInstruction, tiltPosition)}
									</div>
									<div
										className="flex justify-between"
										onClick={() => {
											if (!isAccuratePosition) {
												stopAllAudio()
												clearTimeout(simpleBackgroundTimeoutRef.current!)
												if (activeInstruction === 1) {
													playSkinTightLongHairAudio()
													simpleBackgroundTimeoutRef.current = setTimeout(
														() => {
															stopAllAudio()
															playSimpleBackgroundAudio()
														},
														6000
													)
												}

												if (activeInstruction === 2) {
													clearTimeout(simpleBackgroundTimeoutRef.current!)
													playTiltPhoneAudio()
												}
											}
										}}
									>
										<div className="basis-1/12 grid place-content-center cursor-pointer">
											{activeInstruction > 0 && !isAccuratePosition && (
												<span
													className="cursor-pointer flex flex-row"
													onClick={() =>
														setActiveInstruction(activeInstruction - 1)
													}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														strokeWidth={2}
														stroke="currentColor"
														className="w-5 h-5 mr-1"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M15.75 19.5L8.25 12l7.5-7.5"
														/>
													</svg>
													<span className="text-sm">Back</span>
												</span>
											)}
										</div>

										<div className="basis-1/12 grid place-content-center cursor-pointer">
											{activeInstruction < 2 && !isAccuratePosition && (
												<Button
													className="w-min px-3 text-xs py-1"
													title="Next"
													onClick={() => {
														setActiveInstruction(activeInstruction + 1)
													}}
												/>
											)}
										</div>
									</div>
								</div>
							}
							open={showInstruction}
							transparent
							noButton
							className="opacity-70"
							onConfirm={() => {}}
							onClose={() => setShowInstruction(false)}
						/>

						<Modal
							showClose
							children={
								<div className="h-[75vh] text-center">
									<h2 className="font-semibold text-md mt-8 px-6 h-14">
										{animatedText}
									</h2>
									<p className="mt-5 text-xs">{animatedSubText}</p>
									<img
										className="mx-auto h-auto w-auto mt-10"
										src={`assets/animations/instruction-${
											infoStore.gender.value === MALE_GENDER ? 'male' : 'female'
										}.gif`}
									/>
								</div>
							}
							open={showGuide}
							transparent
							noButton
							className="h-[95vh self-center"
							onConfirm={() => {}}
							onClose={() => setShowGuide(false)}
						/>

						<div
							className={`absolute z-[2000000] top-5 left-2 ${
								showGuide ? 'invisible' : 'visible'
							}`}
						>
							<div className="bg-gray-500 opacity-70 cursor-pointer grid justify-center items-center rounded-full m-1 p-2">
								<ChevronLeftIcon
									onClick={() => onBack!()}
									className="h-6 w-6 text-white font-bold mx-auto"
								/>
							</div>
						</div>

						<div
							onClick={() => {
								stopAllAudio()
								setShowGuide(true)
							}}
							className={`absolute z-[2000000] top-5 right-2 ${
								showGuide ? 'invisible' : 'visible'
							}`}
						>
							<div className="bg-white grid justify-center items-center rounded-full p-2">
								<BookOpenIcon
									className="h-6 w-6 text-black font-bold mx-auto"
									aria-hidden="true"
								/>
							</div>
						</div>
						<div
							className={`absolute z-1 bottom-10 left-0 right-0 ${
								activeInstruction < 3 || videoRecordingComplete
									? 'invisible'
									: 'visible'
							}`}
						>
							<img
								src="assets/images/silhouette.svg"
								className="mx-auto w-full h-[80vh]"
							/>
						</div>

						<div
							className={`absolute z-1 bottom-5 right-2 ${
								showInstruction || videoRecordingComplete
									? 'invisible'
									: 'visible'
							}`}
						>
							<div
								onClick={() => setShowInstruction(true)}
								className="bg-black grid justify-center items-center rounded-full m-1 p-2"
							>
								<QuestionMarkCircleIcon
									className="h-8 w-8 text-white font-bold  mx-auto"
									aria-hidden="true"
								/>
							</div>
						</div>

						<div
							className={`absolute z-1 top-32 left-2 ${
								activeInstruction === 3 ? 'visible' : 'invisible'
							}`}
						>
							<div className="relative py-8 bg-white w-[15px] h-52 rounded-full justify-center">
								<div className="relative z-0 bg-green-500 w-[15px] h-7"></div>
								<div
									className="absolute z-1 bg-black rounded-full w-[15px] h-[15px]"
									style={{ top: `${tiltPosition}px` }}
								></div>
							</div>
						</div>
					</div>
				</Layout>
			)}
		</Fragment>
	)
}

export default RecordVideo
