import { h } from 'preact'

const Instruction = (status: number, tiltPosition: number) => {
	switch (status) {
		case 0:
			return (
				<div>
					<h2 className="font-semibold text-md">
						Unmute & Turn
						<br /> SOUND UP
					</h2>
					<img
						width="120px"
						className="mx-auto mt-2"
						src="assets/animations/unmute.gif"
					/>
				</div>
			)
		case 1:
			return (
				<div className="grid">
					<div className="flex gap-6 text-start">
						<img
							width="24px"
							src="assets/animations/skin-tight-long-hair.gif"
						/>
						<h2 className="font-semibold text-md">
							Wear skintight clothing and tie long hair up for accurate results
						</h2>
					</div>
					<div className="flex gap-6 mt-4">
						<img width="37px" src="assets/animations/simple-background.gif" />
						<div className="font-semibold text-md">Simple Background</div>
					</div>
				</div>
			)
		case 2:
			return (
				<div className="flex gap-4">
					<div className="">
						<div className="relative py-8 bg-gray-300 w-[15px] h-52 rounded-full justify-center">
							<div className="relative z-0 bg-green-500 w-[15px] h-7"></div>
							<div
								className="absolute z-1 bg-black rounded-full w-[15px] h-[15px]"
								style={{ top: `${tiltPosition}px` }}
							></div>
						</div>
					</div>
					<div>
						<img
							className="mx-auto w-20"
							src="assets/animations/tilt-phone.gif"
						/>
						<h2 className="font-semibold text-lg mt-3 px-3">
							Lean your phone against wall on the floor & tilt it until dot is
							in the green area
						</h2>
					</div>
				</div>
			)
		case 3:
			return (
				<div>
					<img className="mx-auto w-20" src="assets/animations/step-back.gif" />
					<h2 className="font-semibold text-md mt-2 px-6">
						Take 2 steps back. Arms and legs apart. On the beep, rotate 360°
						slowly
					</h2>
				</div>
			)
		default:
			break
	}
}

export default Instruction
