import { getContentService } from 'services/scan'
import { getIpService, trackEventService } from 'services/utility'

export const trackEvent = async (body: any) => {
	return trackEventService(body)
		.then(() => {
			return ''
		})
		.catch((err) => {
			console.log(err)
		})
		.finally(() => {}) as Promise<string>
}

export const getIp = async () => {
	return getIpService()
		.then((res) => {
			return res['ip']
		})
		.catch((err) => {
			console.log(err)
		})
		.finally(() => {}) as Promise<string>
}

export const getContent = async (name: string) => {
	return getContentService(name)
		.then((response: any) => {
			return response.data
		})
		.catch((err) => {
			console.log(err)
		})
		.finally(() => {}) as Promise<string>
}
