import { getIp, trackEvent } from 'actions/utility'
import { getBrowserId, getScanId, isEmpty } from './index'

const win = window as any
const opera = win.opera
const userAgent = navigator.userAgent
let vendor = navigator.vendor
let screen = win.screen
let ip = ''

export const track = async (action: string, options = {}) => {
	if (ip === '') {
		getIp().then((res) => {
			ip = res
		})
	}

	const configs = {
		$os: os(),
		$browser: browser(),
		$browser_version: browserVersion(),
		$screen_height: getScreenHeight(),
		$screen_width: getScreenWidth(),
		ip,
		$device_id: getBrowserId(),
	} as any

	if (!isEmpty(referringDomain())) {
		configs['$current_url'] = referringDomain()
	}

	if (!isEmpty(device())) {
		configs['$device'] = device()
	}

	trackEvent({
		distinct_id: getScanId(),
		name: action,
		properties: {
			...options,
			...configs,
		},
	})
}

const includes = (str: string, needle: string) => {
	return str.indexOf(needle) !== -1
}

const getScreenHeight = () => {
	return screen.height
}

const getScreenWidth = () => {
	return screen.width
}

/**
 * This function detects which browser is running this script.
 * The order of the checks are important since many user agents
 * include key words used in later checks.
 */
export const browser = () => {
	vendor = vendor || '' // vendor is undefined for at least IE9
	if (opera || includes(userAgent, 'OPR/')) {
		if (includes(userAgent, 'Mini')) {
			return 'Opera Mini'
		}
		return 'Opera'
	} else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
		return 'BlackBerry'
	} else if (
		includes(userAgent, 'IEMobile') ||
		includes(userAgent, 'WPDesktop')
	) {
		return 'Internet Explorer Mobile'
	} else if (includes(userAgent, 'SamsungBrowser/')) {
		// https://developer.samsung.com/internet/user-agent-string-format
		return 'Samsung Internet'
	} else if (includes(userAgent, 'Edge') || includes(userAgent, 'Edg/')) {
		return 'Microsoft Edge'
	} else if (includes(userAgent, 'FBIOS')) {
		return 'Facebook Mobile'
	} else if (includes(userAgent, 'Chrome')) {
		return 'Chrome'
	} else if (includes(userAgent, 'CriOS')) {
		return 'Chrome iOS'
	} else if (includes(userAgent, 'UCWEB') || includes(userAgent, 'UCBrowser')) {
		return 'UC Browser'
	} else if (includes(userAgent, 'FxiOS')) {
		return 'Firefox iOS'
	} else if (includes(vendor, 'Apple')) {
		if (includes(userAgent, 'Mobile')) {
			return 'Mobile Safari'
		}
		return 'Safari'
	} else if (includes(userAgent, 'Android')) {
		return 'Android Mobile'
	} else if (includes(userAgent, 'Konqueror')) {
		return 'Konqueror'
	} else if (includes(userAgent, 'Firefox')) {
		return 'Firefox'
	} else if (includes(userAgent, 'MSIE') || includes(userAgent, 'Trident/')) {
		return 'Internet Explorer'
	} else if (includes(userAgent, 'Gecko')) {
		return 'Mozilla'
	} else {
		return ''
	}
}

/**
 * This function detects which browser version is running this script,
 * parsing major and minor version (e.g., 42.1). User agent strings from:
 * http://www.useragentstring.com/pages/useragentstring.php
 */
export const browserVersion = () => {
	const b = browser()
	const versionRegexs = {
		'Internet Explorer Mobile': /rv:(\d+(\.\d+)?)/,
		'Microsoft Edge': /Edge?\/(\d+(\.\d+)?)/,
		Chrome: /Chrome\/(\d+(\.\d+)?)/,
		'Chrome iOS': /CriOS\/(\d+(\.\d+)?)/,
		'UC Browser': /(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/,
		Safari: /Version\/(\d+(\.\d+)?)/,
		'Mobile Safari': /Version\/(\d+(\.\d+)?)/,
		Opera: /(Opera|OPR)\/(\d+(\.\d+)?)/,
		Firefox: /Firefox\/(\d+(\.\d+)?)/,
		'Firefox iOS': /FxiOS\/(\d+(\.\d+)?)/,
		Konqueror: /Konqueror:(\d+(\.\d+)?)/,
		BlackBerry: /BlackBerry (\d+(\.\d+)?)/,
		'Android Mobile': /android\s(\d+(\.\d+)?)/,
		'Samsung Internet': /SamsungBrowser\/(\d+(\.\d+)?)/,
		'Internet Explorer': /(rv:|MSIE )(\d+(\.\d+)?)/,
		Mozilla: /rv:(\d+(\.\d+)?)/,
	} as any
	var regex = versionRegexs[b]
	if (regex === undefined) {
		return null
	}
	var matches = userAgent.match(regex)
	if (!matches) {
		return null
	}
	return parseFloat(matches[matches.length - 2])
}

export const os = () => {
	var a = navigator.userAgent
	if (/Windows/i.test(a)) {
		if (/Phone/.test(a) || /WPDesktop/.test(a)) {
			return 'Windows Phone'
		}
		return 'Windows'
	} else if (/(iPhone|iPad|iPod)/.test(a)) {
		return 'iOS'
	} else if (/Android/.test(a)) {
		return 'Android'
	} else if (/(BlackBerry|PlayBook|BB10)/i.test(a)) {
		return 'BlackBerry'
	} else if (/Mac/i.test(a)) {
		return 'Mac OS X'
	} else if (/Linux/.test(a)) {
		return 'Linux'
	} else if (/CrOS/.test(a)) {
		return 'Chrome OS'
	} else {
		return ''
	}
}

export const device = () => {
	if (/Windows Phone/i.test(userAgent) || /WPDesktop/.test(userAgent)) {
		return 'Windows Phone'
	} else if (/iPad/.test(userAgent)) {
		return 'iPad'
	} else if (/iPod/.test(userAgent)) {
		return 'iPod Touch'
	} else if (/iPhone/.test(userAgent)) {
		return 'iPhone'
	} else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
		return 'BlackBerry'
	} else if (/Android/.test(userAgent)) {
		return 'Android'
	} else {
		return ''
	}
}

const referringDomain = () => {
	var split = win.document.referrer.split('/')
	if (split.length >= 3) {
		return split[2]
	}
	return ''
}
