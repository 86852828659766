import { deepSignal } from '@deepsignal/preact'
import { effect } from '@preact/signals'
import {
	FIT_PREFERENCE_STORAGE,
	REGULAR_FITTING,
	TOP_SECTION,
} from 'utils/config'
import storage from 'utils/storage'

const getInitialFitPreferenceStore = (): FitPreferenceStorage => {
	const fitPreference = storage.get(FIT_PREFERENCE_STORAGE)

	if (fitPreference) {
		return fitPreference
	} else {
		return {
			topFitting: REGULAR_FITTING,
			bottomFitting: REGULAR_FITTING,
		}
	}
}

const fitPreferenceStore = {
	...deepSignal({
		...getInitialFitPreferenceStore(),
		...{ fitPreferenceTab: TOP_SECTION },
	}),
	setTopFitting(fitting: string) {
		this.topFitting.value = fitting
	},
	setBottomFitting(fitting: string) {
		this.bottomFitting.value = fitting
	},
	setFitPreference(fitting: string) {
		this.bottomFitting.value = fitting
	},
	setFitPreferenceTab(tab: string) {
		this.fitPreferenceTab.value = tab
	},
	getAll() {
		return {
			topFitting: this.topFitting.value,
			bottomFitting: this.bottomFitting.value,
		}
	},
}

effect(() => storage.set(FIT_PREFERENCE_STORAGE, fitPreferenceStore.getAll()))

export default fitPreferenceStore
