import { getConfigurationService } from 'services/initialize'
import {
	ConfigurationResponse,
	ConfigurationResponseValue,
} from 'types/configuration'

export const getConfiguration = async () => {
	return getConfigurationService()
		.then((response: ConfigurationResponse) => {
			return response.data[0].value
		})
		.catch((err) => {
			console.log(err)
		})
		.finally(() => {}) as Promise<ConfigurationResponseValue>
}
